import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PreRegistration from "./components/PreRegistration";
import ThankYou from "./components/ThankYou";
import NotFound from "./components/NotFound";
import Login from "./components/Login";
import Admin from "./components/Admin";
import { ThemeProvider, createTheme } from "@mui/material";
import ReactGA from "react-ga4";

ReactGA.initialize("G-ZTYJ7638YL");

function App() {
    const theme = createTheme({
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        marginRight: "20px",
                        marginLeft: "20px",
                        color: "white",
                        "& .MuiInputLabel-root": {
                            color: "white", // Change the color of the label
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                            color: "white", // Change the color of the focused label
                        },
                        "& .MuiInputBase-input::placeholder": {
                            color: "white", // Change the color of the placeholder
                            opacity:1
                        },
                        "& .MuiOutlinedInput-root": {
                            "::placeholder": {
                                color: "white",
                            },
                            color: "white",
                            "& fieldset": {
                                borderColor: "white",
                                border: "1px solid",
                            },
                            "&:hover fieldset": {
                                borderColor: "white",
                                border: "1px solid",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "white",
                                border: "1px solid",
                            },
                        }
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/" element={<PreRegistration />} />
                    <Route path="/home" element={<PreRegistration />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/register" element={<PreRegistration />} />
                    <Route path="/thank-you" element={<ThankYou />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
