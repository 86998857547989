import React, { useState, useEffect } from "react";
import {
    Paper,
    TextField,
    Typography,
    Button,
    InputAdornment,
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import ReactGA from "react-ga4";
import api from "./api";
import { api_credentials } from "../var";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";

export default function PreRegistration() {
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + window.location.search,
            title: "Preregister page",
        });
    }, []);
    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        ReactGA.event({
            category: "Clicks",
            action: "Preregister click",
            label: data.get("email"), // optional
        });
        data.set("cv", file);
        data.set("key", api_credentials.key);
        data.set("api_user", api_credentials.user);
        api.post("/add-user", data, {
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((res) => {
                const _data = res.data;
                console.log(_data);
                if (_data.Status) {
                    setTimeout(() => {
                        navigate("/thank-you");
                    }, 2000);
                }
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };
    if (loading) {
        return (
            <div
                style={{
                    height: "100vh",
                }}
            >
                <SyncLoader
                    size={25}
                    color={"#003063"}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                    }}
                />
            </div>
        );
    }
    return (
        <div className="container-preregister">
        <div
            style={{
                position: "absolute",
                top: "200px",
                left: "50%",
                marginRight: "10%",
                borderRadius: "25px",
                transform: "translateX(1%)",
            }}
            className="box-preregister"
        >
            <Paper
                elevation={0}
                sx={{
                    p: 2,
                    display: "grid",
                    background: "transparent",

                    borderRadius: "25px",
                }}
                component={"form"}
                noValidate={false}
                onSubmit={handleSubmit}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                    className="logos"
                >
                    <div
                        className="tron-logo"
                        style={{
                            width: "250px",
                            height: "80px",
                        }}
                    ></div>
                    <div
                        style={{
                            color: "white",
                            fontSize: "40px",
                            fontWeight: "900",
                            marginTop: "-20px",
                        }}
                        className="big-font"
                    >
                        {" "}
                        IT JOBS
                    </div>
                </div>
                <Typography
                    paragraph
                    sx={{
                        fontWeight: "bold",
                        fontSize: 20,
                        textJustify: "inter-word",
                        textAlign: "justify",
                        color: "white",
                        margin: "30px 0 ",
                    }}
                >
                    <span
                        style={{
                            textAlign: "justify",
                            fontWeight: "900",
                            fontSize: "28px",
                        }}
                        className="big-font"
                    >
                        Ești în căutarea unui nou rol sau îți dorești să
                        întâlnești angajatorul potrivit pentru tine?
                    </span>
                    <br />
                    <span
                        style={{
                            textAlign: "justify",
                            fontWeight: "900",
                            fontSize: "28px",
                            lineHeight: "90px",
                        }}
                        className="big-font height-line-big"
                    >
                        {" "}
                        Noi avem soluția!
                    </span>{" "}
                    <br />
                    <span
                        style={{
                            textAlign: "justify",
                            fontWeight: "400",
                            fontSize: "18px",
                        }}
                        className="small-font"
                    >
                        Într-o piață IT competitivă este adesea dificil să
                        găsești rolul sau angajatorul potrivit, tocmai de aceea
                        te invităm să te înscrii pe pagina noastră. Noi vom
                        căuta pentru tine, iar timpul tău va fi folosit în mod
                        eficient.
                    </span>
                    <br />
                    <br />
                    <span
                        style={{
                            textAlign: "justify",
                            fontWeight: "400",
                            fontSize: "18px",
                        }}
                        className="small-font"
                    >
                        Echipa noastră de recrutori IT experimentați, va sonda
                        piața rolurilor în domeniul IT din România dar și la
                        nivel internațional și vei primi doar acele roluri care
                        se potrivesc opțiunilor tale. Tot ce îți rămâne de făcut
                        este să le verifici personal și să aplici.
                    </span>
                    <br />
                    <br />
                    <span
                        style={{
                            textAlign: "justify",
                            fontWeight: "400",
                            fontSize: "18px",
                        }}
                        className="small-font"
                    >
                        Înscrie-te acum și vei beneficia de un discount de 25%
                        din valoarea abonamentului lunar de 40 Lei, adică vei
                        plăti doar 30 Lei.
                    </span>
                    <br />
                    <br />
                    <span
                        style={{
                            textAlign: "justify",
                            fontWeight: "400",
                            fontSize: "18px",
                        }}
                        className="small-font"
                    >
                        După găsirea rolului sau oricând consideri tu, poți
                        anula abonamentul sau îl poți prelungi.
                    </span>
                </Typography>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <TextField
                        sx={{
                            gridRow: "1/2",
                            gridColumn: "1/2",
                            mt: 3,
                            mb: 2,
                            mr: 3,
                            ml: 0,
                        }}
                        InputProps={{
                            sx: {
                                borderRadius: "25px",
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            ),
                        }}
                        required
                        name="first_name"
                        placeholder="Nume"
                        className="name"
                    />
                    <TextField
                        sx={{
                            gridRow: "1/2",
                            gridColumn: "2/3",
                            mt: 3,
                            mb: 2,
                            mr: 0,
                            ml: 3,
                        }}
                        InputProps={{
                            sx: {
                                borderRadius: "25px",
                                borderColor: "#003063",
                                "& ::placeholder": {
                                    color: "white",
                                },
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        required
                        name="email"
                        placeholder="Email"
                        className="email"
                    />
                    <MuiFileInput
                        sx={{
                            gridRow: "2/3",
                            gridColumn: "1/2",
                            width: "100%",
                            color: "white",
                            mt: 3,
                            mb: 2,
                            mr: 0,
                            ml: 0,
                        }}
                        InputProps={{
                            sx: {
                                borderRadius: "25px",
                                borderColor: "white",
                                color: "white",
                                mr: 3,
                            },
                        }}
                        value={file}
                        onChange={(newFile) => setFile(newFile)}
                        placeholder="Upload CV"
                        className="cv"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            mr: 0,
                            ml: 0,
                            gridRow: "2/3",
                            gridColumn: "2/3",
                            width: "50%",
                            placeSelf: "flex-end",
                            backgroundColor: "#2b39,8f",
                            borderRadius: "25px",
                            border: "1px solid white",
                            textTransform: "none",
                            height: "56px",
                        }}
                        className="submit-btn"
                    >
                        Mă înscriu
                    </Button>
                </div>
            </Paper>
        </div>
        </div>
    );
}
