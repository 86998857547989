/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../styles/login.css";
import {
    Paper,
    TextField,
    Typography,
    Button,
    InputAdornment,
    OutlinedInput,
    IconButton,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import ReactGA from "react-ga4";
import api from "./api";
import { api_credentials } from "../var";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PopUp from "./PopUp";

export default function Login() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [popUpData, setPopUpData] = useState({});
    const checkLogin = () => {
        const _data = {
            api_user: api_credentials.user,
            key: api_credentials.key,
            email: localStorage.getItem("email"),
            password: localStorage.getItem("password"),
        };
        console.log(_data)
        if (!_data.email || !_data.password) return;
        api.post("/login", _data)
            .then((res) => {
                const _data = res.data;
                console.log(_data);
                setLoading(false);
                if (_data.Status) {
                    navigate("/admin");
                }
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };
    useEffect(() => {
        checkLogin();
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + window.location.search,
            title: "Login page",
        });
    }, []);
    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked);
    };
    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        ReactGA.event({
            category: "Clicks",
            action: "Login click",
            label: data.get("email"), // optional
        });
        const _data = {
            api_user: api_credentials.user,
            key: api_credentials.key,
            email: data.get("email"),
            password: data.get("password"),
        };
        api.post("/login", _data)
            .then((res) => {
                const _data = res.data;
                setLoading(false);
                if (_data.Status) {
                    if (rememberMe) {
                        localStorage.setItem("email", data.get("email"));
                        localStorage.setItem("password", data.get("password"));
                    }
                    navigate("/admin", {state:{
                        email : data.get("email"),
                        password: data.get("password")
                    }});
                } else {
                    setPopUpData({
                        open: true,

                        data: {
                            title: "Something went wrong!",
                            content: _data.Message,
                            isConfirm: false,
                            closeText: "Ok",
                            onClose: () => setShowPopUp(false),
                        },
                    });
                    setShowPopUp(true);
                }
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setPopUpData({
                    open: true,

                    data: {
                        title: "API ERROR WHILE FETCHING!",
                        content: error,
                        isConfirm: false,
                        closeText: "Ok",
                        onClose: () => setShowPopUp(false),
                    },
                });
                setShowPopUp(true);
            });
    };

    return (
        <div className="container-login">
            {showPopUp && <PopUp {...popUpData} />}
            {loading && (
                <SyncLoader
                    size={25}
                    color={"#003063"}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                    }}
                />
            )}
            <Paper
                elevation={0}
                className="box-login"
                sx={{
                    p: 2,
                    display: "grid",
                    background: "transparent",
                    position: "absolute",
                    top: "400px",
                    left: "50%",
                    width: "30vw",
                    borderRadius: "25px",
                    transform: "translateX(1%)",
                }}
                component={"form"}
                noValidate={false}
                onSubmit={handleSubmit}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                    className="logos"
                >
                    <div
                        className="tron-logo"
                        style={{
                            width: "250px",
                            height: "80px",
                        }}
                    ></div>
                    <div
                        style={{
                            color: "white",
                            fontSize: "40px",
                            fontWeight: "900",
                            marginTop: "-15px",
                        }}
                        className="big-font-login"
                    >
                        {" "}
                        IT JOBS
                    </div>
                </div>
                <Typography
                    paragraph
                    sx={{
                        fontWeight: "bold",
                        fontSize: 28,
                        textJustify: "inter-word",
                        textAlign: "center",
                        color: "white",
                        margin: "30px 0 ",
                    }}
                >
                    Login
                </Typography>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    {" "}
                    <TextField
                        sx={{
                            gridRow: "1/2",
                            gridColumn: "1/3",
                            mt: 3,
                            mb: 2,
                            mr: 0,
                            ml: 0,
                        }}
                        InputProps={{
                            sx: {
                                borderRadius: "25px",
                                borderColor: "#003063",
                                "& ::placeholder": {
                                    color: "white",
                                },
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        required
                        name="email"
                        placeholder="Email"
                        className="email-login"
                    />
                    <OutlinedInput
                        name="password"
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        sx={{
                            gridRow: "2/3",
                            gridColumn: "1/3",
                            border: "1px solid white",
                            borderRadius: "25px",
                            color: "white",
                            "& ::placeholder": {
                                color: "white",
                                opacity: "1 !important",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "0px ",
                            },
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                        setShowPassword(
                                            (prevState) => !prevState
                                        )
                                    }
                                    onMouseDown={(event) =>
                                        event.preventDefault()
                                    }
                                >
                                    {showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                        startAdornment={
                            <InputAdornment position="start">
                                <LockIcon />
                            </InputAdornment>
                        }
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                value="remember"
                                color="primary"
                                onChange={handleRememberMeChange}
                                sx={{
                                    color: "white",
                                }}
                            />
                        }
                        label="Retine datele de logare"
                        sx={{
                            gridRow: "3/4",
                            color: "white",
                            mr: 0,
                            ml: 0,
                        }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            mr: 0,
                            ml: 0,
                            gridRow: "3/4",
                            gridColumn: "2/3",
                            width: "80%",
                            placeSelf: "flex-end",
                            backgroundColor: "#2b39,8f",    
                            borderRadius: "25px",
                            border: "1px solid white",
                            textTransform: "none",
                            height: "56px",
                        }}
                        className="submit-btn-login"
                    >
                        Login
                    </Button>
                </div>
            </Paper>
        </div>
    );
}
