/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../styles/admin.css";
import { Paper, Typography, IconButton } from "@mui/material";
import ReactGA from "react-ga4";
import api from "./api";
import { api_credentials } from "../var";
import { useNavigate, useLocation } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { DataGrid } from "@mui/x-data-grid";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", flex: 1, minWidth: 150 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 150 },
    {
        field: "cv",
        headerName: "CV",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
            return (
                <IconButton
                    onClick={() =>
                        window.open(`/CVs/${params?.row?.cvID}.pdf`, "_blank")
                    }
                >
                    <PictureAsPdfIcon />
                </IconButton>
            );
        },
    },
];

export default function Admin() {
    const navigate = useNavigate();
    const navigationProps = useLocation();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const checkLogin = () => {
        setLoading(true);
        const _data = {
            api_user: api_credentials.user,
            key: api_credentials.key,
            email: localStorage.getItem("email"),
            password: localStorage.getItem("password"),
        };
        if (!_data.email || !_data.password){
            const email = navigationProps?.state?.email
            const password = navigationProps?.state?.password
            if( !email || !password){

                navigate('/login')
                return
            }else{
                _data.email = email
                _data.password = password
            }
        };
        api.post("/login", _data)
            .then((res) => {
                const _data = res.data;
                setLoading(false);
                if (!_data.Status) {
                    navigate("/login");
                }
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };
    const getData = () => {
        const _data = {
            api_user: api_credentials.user,
            key: api_credentials.key,
        };
        api.post("/get-data", _data)
            .then((res) => {
                const _data = res.data;
                setLoading(false);
                const __data = [];
                _data?.Message?.forEach((user) => {
                    __data.push({
                        id: user?.id,
                        name: user?.name,
                        email: user?.email,
                        cv: "Click here to open CV",
                        cvID: user?.cv,
                    });
                });
                setUsers(__data);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };
    useEffect(() => {
        checkLogin();
        getData();
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + window.location.search,
            title: "Admin page",
        });
    }, []);
    if (loading) {
        return (
            <div
                style={{
                    height: "100vh",
                }}
            >
                <SyncLoader
                    size={25}
                    color={"#003063"}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                    }}
                />
            </div>
        );
    }
    return (
        <div className="container-admin">

            <Paper
                elevation={0}
                sx={{
                    p: 2,
                    display: "grid",
                    background: "transparent",
                    position: "absolute",
                    top: "200px",
                    left: "5%",
                    width: "90vw",
                    borderRadius: "25px",
                    transform: "translateX(0.0000001px)",
                }}
                className="box-admin"
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                    className="logos"
                >
                    <div
                        className="tron-logo"
                        style={{
                            width: "250px",
                            height: "80px",
                        }}
                    ></div>
                    <div
                        style={{
                            color: "white",
                            fontSize: "40px",
                            fontWeight: "900",
                            marginTop: "-15px",
                        }}
                        className="big-font"
                    >
                        {" "}
                        IT JOBS
                    </div>
                </div>
                <Typography
                    paragraph
                    sx={{
                        fontWeight: "bold",
                        fontSize: 28,
                        textJustify: "inter-word",
                        textAlign: "center",
                        color: "white",
                        margin: "30px 0 ",
                    }}
                >
                    Utilizatori
                </Typography>
                <div
                    style={{
                        backgroundColor: "white",

                        borderRadius: "25px",
                    }}
                >
                    <div>
                        <DataGrid
                            rows={users}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 20]}
                            sx={{
                                margin: 3,
                                // color:'white'
                            }}
                        />
                    </div>
                </div>
            </Paper>
        </div>
    );
}
